$(document).ready(function()
{
	/*
	 * ------------------------------------------------------
	 *  Fancybox
	 * ------------------------------------------------------
	 */
	if( $.fn.fancybox )
	{
		$('.fancybox').fancybox();
	}

	/*
	 * ------------------------------------------------------
	 *  Menu offCanvas
	 * ------------------------------------------------------
	 */
	if( typeof MenuOffCanvasSwipe == 'function' )
	{
		new MenuOffCanvasSwipe();
	}

	/*
	 * ------------------------------------------------------
	 *  Validação
	 * ------------------------------------------------------
	 */
	if( $.fn.validate )
	{
		/*
		 * Translated default messages for the jQuery validation plugin.
		 * Locale: PT (Portuguese; português)
		 * Region: BR (Brazil)
		 */
		$.extend($.validator.messages, {
			required    : "Este campo &eacute; obrigatório.",
			remote      : "Por favor, corrija este campo.",
			email       : "Por favor, forne&ccedil;a um endere&ccedil;o de email v&aacute;lido.",
			url         : "Por favor, forne&ccedil;a uma URL v&aacute;lida.",
			date        : "Por favor, forne&ccedil;a uma data v&aacute;lida.",
			dateISO     : "Por favor, forne&ccedil;a uma data v&aacute;lida (ISO).",
			number      : "Por favor, forne&ccedil;a um n&uacute;mero v&aacute;lido.",
			digits      : "Por favor, forne&ccedil;a somente d&iacute;gitos.",
			creditcard  : "Por favor, forne&ccedil;a um cart&atilde;o de cr&eacute;dito v&aacute;lido.",
			equalTo     : "Por favor, forne&ccedil;a o mesmo valor novamente.",
			extension   : "Por favor, forne&ccedil;a um valor com uma extens&atilde;o v&aacute;lida.",
			maxlength   : $.validator.format("Por favor, forne&ccedil;a n&atilde;o mais que {0} caracteres."),
			minlength   : $.validator.format("Por favor, forne&ccedil;a ao menos {0} caracteres."),
			rangelength : $.validator.format("Por favor, forne&ccedil;a um valor entre {0} e {1} caracteres de comprimento."),
			range       : $.validator.format("Por favor, forne&ccedil;a um valor entre {0} e {1}."),
			max         : $.validator.format("Por favor, forne&ccedil;a um valor menor ou igual a {0}."),
			min         : $.validator.format("Por favor, forne&ccedil;a um valor maior ou igual a {0}."),
			nifES       : "Por favor, forne&ccedil;a um NIF v&aacute;lido.",
			nieES       : "Por favor, forne&ccedil;a um NIE v&aacute;lido.",
			cifEE       : "Por favor, forne&ccedil;a um CIF v&aacute;lido.",
			postalcodeBR: "Por favor, forne&ccedil;a um CEP v&aacute;lido.",
			cpfBR       : "Por favor, forne&ccedil;a um CPF v&aacute;lido."
		});

		$.validator.setDefaults({
			highlight     : function(element)
			{
				$(element).closest('.form-group').addClass('has-error');
			},
			unhighlight   : function(element)
			{
				$(element).closest('.form-group').removeClass('has-error');
			},
			errorElement  : 'span',
			errorClass    : 'help-block',
			errorPlacement: function(error, element)
			{
				if( element.parent('.input-group').length )
				{
					error.insertAfter(element.parent());
				}
				else
				{
					error.insertAfter(element);
				}
			}
		});

		// Valida os forms
		$('[data-validate]').validate();
	}

	/*
	 * ------------------------------------------------------
	 *  Máscaras
	 * ------------------------------------------------------
	 */
	if( $.fn.inputmask )
	{
		// Não mostra a mascara durante o hover
		Inputmask.prototype.defaults.showMaskOnHover = false;

		// Telefone
		var mascara_telefone = $('.mascara-telefone');
		if( mascara_telefone.length )
		{
			mascara_telefone.inputmask("(99) 9999-9999[9]");
			mascara_telefone.on('focusout', function()
			{
				var elemento_at, element;

				element = $(this);

				var telefone = element.val().replace(/\D/g, "");

				if( telefone.length > 10 )
				{
					element.inputmask("(99) 9999-99999");
				} else
				{
					element.inputmask("(99) 9999-9999[9]");
				}

			});

			mascara_telefone.trigger("focusout");
		}

		// Data
		var mascara_data = $('.mascara-data');
		if( mascara_data.length )
		{
			mascara_data.inputmask("99/99/9999");
		}

		// Hora
		var mascara_hora = $('.mascara-hora');
		if( mascara_hora.length )
		{
			mascara_hora.inputmask("99:99");
		}

		// Cep
		var mascara_cep = $('.mascara-cep');
		if( mascara_cep.length )
		{
			mascara_cep.inputmask("99999-999");
		}

		// CPF
		var mascara_cpf = $('.mascara-cpf');
		if( mascara_cpf.length )
		{
			mascara_cpf.inputmask("999.999.999-99");
		}

		// CPNJ
		var mascara_cnpj = $('.mascara-cnpj');
		if( mascara_cnpj.length )
		{
			mascara_cnpj.inputmask("99.999.999/9999-99");
		}
	}

	/*
	 * ------------------------------------------------------
	 *  Slick
	 * ------------------------------------------------------
	 */
	if( $.fn.slick )
	{
		$("[data-slick]").slick();
	}

	/*
	 * ------------------------------------------------------
	 *  Isotope
	 * ------------------------------------------------------
	 */
	if( $.fn.isotope )
	{
		// Corrige bug no chrome :(
		$(window).load(function()
		{
			$('.js-isotope').isotope('layout');
		});
	}

	/*
	 * ------------------------------------------------------
	 *  Topo fixado
	 * ------------------------------------------------------
	 */
	$(window).scroll(function()
	{
		if( $(document).scrollTop() > 50 )
		{
			$('body').addClass('topo-fixado');
		} else
		{
			$('body').removeClass('topo-fixado');
		}
	});

	/*
	 * ------------------------------------------------------
	 *  Home
	 * ------------------------------------------------------
	 */
	if( $('body').hasClass('body-home') )
	{
		$('.form-contato').validate({
			submitHandler: function(form)
			{
				var obj = $(form);
                obj.find('button[type="submit"]').slideUp();

				$.post(obj.attr('action'),
					obj.serialize(),
					function(data)
					{
						swal({
							type : ( data.status == 'sucesso' ) ? 'success' : 'error',
							title: data.titulo,
							text : data.mensagem
						});

						if( data.status == 'sucesso' )
						{
							form.reset();
                            obj.find('button[type="submit"]').slideDown();
						}
					}, 'json');
			}
		});

		// Rola até o bloco
		function ir_para_bloco(bloco)
		{
			var bloco_obj = $('#site-corpo .bloco-' + bloco);

			if( bloco_obj.length )
			{
				var goTo = bloco_obj.offset().top - 70;

				$("html, body").stop().animate({scrollTop: goTo}, 500);
			}
		}

		// Se está acessando algum bloco
		if( window.location.hash )
		{
			var bloco = window.location.hash.split('#');

			if( bloco[1] )
			{
				ir_para_bloco(bloco[1]);
			}
		}

		// Ao clicar em item de bloco no menu
		$('.menu-navegacao a.link-bloco').on('click', function(e)
		{
			var obj   = $(this),
				bloco = obj.attr('data-bloco');

			ir_para_bloco(bloco);

			// Fecha o menu mobile
			if( Modernizr.mq('(max-width: 767px)') )
			{
				MenuOffCanvasSwipe.prototype._openCloseMenu('close');
			}
		});

		var mapa_google = $(".mapa-google");
		if( mapa_google.length )
		{
			var mapa_google_gmap;

			// Carrega o js do google mapa
			var script  = document.createElement('script');
			script.type = 'text/javascript';
			script.src  = 'https://maps.googleapis.com/maps/api/js?v=3.exp' + '&signed_in=true&callback=inicia_mapa';
			document.body.appendChild(script);

			window.inicia_mapa = function()
			{
				var mapa_google          = $(".mapa-google");
				var carregar_mapa_google = mapa_google.find("#carregar-mapa");
				var mapa_google_endereco = mapa_google.attr("data-endereco");
				var latitude             = mapa_google.attr('data-latitude');
				var longitude            = mapa_google.attr('data-longitude');

				if( latitude && longitude )
				{
					var posicao = new google.maps.LatLng(latitude, longitude);

					mapa_google_gmap = new google.maps.Map(carregar_mapa_google[0], {zoom: 17, center: posicao});

					var marcador = new google.maps.Marker({
						position : posicao,
						map      : mapa_google_gmap,
						icon     : 'images/mapa-marker.png',
						clickable: false
					});
				}
				else if( mapa_google_endereco )
				{
					var geocoder = new google.maps.Geocoder();
					geocoder.geocode({'address': mapa_google_endereco}, function(results, status)
					{
						if( status == google.maps.GeocoderStatus.OK )
						{
							var mapa_google_latitude  = results[0].geometry.location.lat();
							var mapa_google_longitude = results[0].geometry.location.lng();

							var posicao = new google.maps.LatLng(mapa_google_latitude, mapa_google_longitude);

							mapa_google_gmap = new google.maps.Map(carregar_mapa_google[0], {zoom: 17, center: posicao});

							var marcador = new google.maps.Marker({
								position : posicao,
								map      : mapa_google_gmap,
								icon     : 'images/mapa-marker.png',
								clickable: false
							});
						}
						else
						{
							carregar_mapa_google.html('Desculpe, mas o Google Maps não conseguiu encontrar este local.');
						}
					});
				}
			}
		}
	}
});
